/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import routes from '../../constants/routes';
import CredentialsType from '../../constants/credentialsType';
import twoFactorTypes from '../../constants/twoFactorTypes';
import { resetPasswordStyles } from '../../jss/inlineStyles';

import { toastr } from 'react-redux-toastr';
import { getFormValues, submit } from 'redux-form';

import FilterPanel from '../shared/FilterPanel';
import Modal from '../shared/Modal';
import MessageDialog from '../shared/MessageDialog';
import Button from '../shared/Button';

import UserUtil from '../util/UserUtil';
import LabelUtil from '../util/LabelUtil';
import IconUtil from '../util/IconUtil';
import ApiAccessUtil from '../util/ApiAccessUtil';

import ApiCredential from './ApiCredential';
import JsSdkForm from './JsSdkForm';
import WebHookForm from './WebHookForm';

import { setModalVisibility } from '../../actions/userExperienceActions';

import {
  clearProxyApiPassword,
  getApiAccess,
  getProxyPassword,
  postApiAccess,
  putApiAccess
} from '../../actions/apiAccessActions';

import { getMerchantSettings } from '../../actions/merchantSettingsActions';

import UpdateSpinner from '../UpdateSpinner';
import TwoFactorTools from '../dialogs/account/TwoFactorTools';
import Page from '../shared/Page';

export class ApiAccess extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestCredentialsTriggered: null,
      resetPasswordTriggered: null,
      shouldDisplayPassword: false,
      loadingSection: false,
      webhookToggle: false,
      incrementalAuthToggle: false,
      password: null,
      loading: false,
    };

    this.openTwoFactorDialog = this.openTwoFactorDialog.bind(this);
    this.closeTwoFactorDialog = this.closeTwoFactorDialog.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);

    this.on2faVerified = this.on2faVerified.bind(this);
    this.on2faProcessing = this.on2faProcessing.bind(this);
    this.on2faClose = this.on2faClose.bind(this);

    this.openWebHookFormHelper = this.openWebHookFormHelper.bind(this);
    this.openJsSdkFormHelper = this.openJsSdkFormHelper.bind(this);

    this.requestCredentials = this.requestCredentials.bind(this);
    this.requestCredentialsHelper = this.requestCredentialsHelper.bind(this);

    this.togglePasswordDialog = this.togglePasswordDialog.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.displayPassword = this.displayPassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);

    this.copyToClipboard = this.copyToClipboard.bind(this);

    this.confirmJsSdkForm = this.confirmJsSdkForm.bind(this);
    this.openJsSdkForm = this.openJsSdkForm.bind(this);
    this.submitJsSdkForm = this.submitJsSdkForm.bind(this);

    this.confirmWebHookForm = this.confirmWebHookForm.bind(this);
    this.openWebHookForm = this.openWebHookForm.bind(this);
    this.toggleWebhookHelper = this.toggleWebhookHelper.bind(this);
    this.toggleWebhook = this.toggleWebhook.bind(this);

    this.toggleAllowIncrementalAuthHelper = this.toggleAllowIncrementalAuthHelper.bind(this);
    this.toggleIncrementalAuth = this.toggleIncrementalAuth.bind(this);

    this.verificationNotice = this.verificationNotice.bind(this);
    this.showToasterSuccess = this.showToasterSuccess.bind(this);

    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidUpdate (prevProps) {
    const { merchantSettings, history, user, auth } = this.props;

    const userChange = !_.isEqual(prevProps.user, user);
    const merchantChange = !_.isEqual(prevProps.merchantSettings.merchantSettings, merchantSettings.merchantSettings);
    const authChange = !_.isEqual(prevProps.auth, auth);

    if (userChange || merchantChange || authChange) {
      const appRoutePrefix = globalApplicationLabel.path;
      const userType = UserUtil.userType(user);
      const shouldRedirect = !_.isEmpty(merchantSettings?.merchantSettings) && (!['MBP', 'PA', 'HYBRID'].includes(userType) || !UserUtil.isIsvAccount(user, auth, merchantSettings));
      if (shouldRedirect) {
        history.push(`${appRoutePrefix}${routes.activity.root}${routes.activity.dashboard}`);
      }
    }
  }

  async componentDidMount() {
    const { dispatch, user, merchantSettings } = this.props;
    await dispatch(getApiAccess(user));

    if (!merchantSettings?.merchantSettings) {
      await dispatch(getMerchantSettings(user));
    }

    const { apiAccess } = this.props;
    const mid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(mid, apiAccess);
    this.setState({ webhookToggle: currentMidSettings.accessWebhook, incrementalAuthToggle: currentMidSettings.semiIntegrationIncrementalAuth });
  }

  resetPassword() {
    const { verification, dispatch } = this.props;
    this.setState({ resetApiPasswordTriggered: true }, () => {
      if (!verification.twoFactorAuthenticationVerified[twoFactorTypes.isvApiAccess]) {
        this.openTwoFactorDialog();
      } else {
        dispatch(setModalVisibility('apiPasswordResetConfirm'));
      }
    });
  }

  togglePasswordDialog(value) {
    const { dispatch } = this.props;
    let type = 'hidden';

    if (value) {
      type = 'apiPasswordDialog';
    }

    dispatch(setModalVisibility(type));

    this.setState({ resetApiPasswordTriggered: false }, () => {
      if (!value) this.hidePassword();
    });
  }

  toggleWebhook() {
    this.setState(prevState => ({ webhookToggle: !prevState.webhookToggle }))
  }

  toggleIncrementalAuth() {
    this.setState(prevState => ({ incrementalAuthToggle: !prevState.incrementalAuthToggle }))
  }

  async toggleWebhookHelper(data) {
    const { webhookToggle } = this.state;
    const webHookCredential = {
      credentialType: CredentialsType.webhookDomain ,
      enableWebHook: !webhookToggle,
      shouldToggle: true,
      ...data };

    await this.requestCredentialsHelper(webHookCredential);
  }

  async openJsSdkFormHelper() {
    const jsSdkFormData = {
      credentialType: CredentialsType.iFrameAPI,
      };
    await this.requestCredentialsHelper(jsSdkFormData);
  }

  async openWebHookFormHelper() {
    const webHookFormData = {
      credentialType: CredentialsType.webhookDomain,
      enableWebHook: true,
    };
    await this.requestCredentialsHelper(webHookFormData);
  }


  async toggleAllowIncrementalAuthHelper(data) {
    const { incrementalAuthToggle } = this.state;
    const semiIntegratedApiCred = {
      credentialType: CredentialsType.semiIntegratedAPI ,
      incrementalAuthToggle: !incrementalAuthToggle,
      shouldToggle: true,
      ...data };

    await this.requestCredentialsHelper(semiIntegratedApiCred);
  }

  async displayPassword() {
    const { dispatch, user } = this.props;
    const { password } = this.state;
    this.setState({ loadingSection : true })

    if (_.isEmpty(password)) {
      await dispatch(getProxyPassword(user));
    }

    const { apiAccess } = this.props;

    const mid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(mid, apiAccess);

    let proxy = currentMidSettings.proxy?.find(proxy => proxy.sdk_primary_key);

    if (!proxy) {
      proxy = currentMidSettings.proxy?.find(proxyElement => !proxyElement.sdk_primary_key)
    }

    this.setState({ shouldDisplayPassword: true, loadingSection: false, password: proxy?.password });
  }

  hidePassword() {
    const { dispatch } = this.props;
    this.setState({ shouldDisplayPassword: false, password: '' }, () => {
      dispatch(clearProxyApiPassword());
    });
  }

  async copyToClipboard (nodeElement) {
    const closestCredentialElement = nodeElement.target.closest('.credential');
    const list = closestCredentialElement.children;
    let field = '';
    let value = '';
    for (const element of list) {
      if (element.className === 'data') {
        for (const dataElement of element.children) {
          if (dataElement.className === 'dataValue') {
            value = dataElement.textContent;
            break;
          } else if (dataElement.className === 'dataKey') {
            field = dataElement.textContent;
          }
        }
        break;
      }
    }

    try {
      await navigator.clipboard.writeText(value);
      toastr.error('Copied', `${field} copied to clipboard`, {
        timeOut: 3000,
        icon: IconUtil.getIcon('ContentCopyIcon', LabelUtil.getLabelColor()),
        progressBar: false,
        removeOnHover: false
      });
    } catch (e) {
      toastr.error('Error');
      return Promise.reject(e);
    }
  };

  getResetPasswordDialogContent() {
    const { shouldDisplayPassword, loadingSection, password } = this.state;
    const { t } = this.props;
    const reverseButtonElement = (
      <Button
        label={t('ApiAccess.ShowPassword')}
        className='showPassword'
        onClick={this.displayPassword}
        fullWidth
        variant='text'
      />
    );
    const credentialElement = password && (
      <div className='credential'>
        <div className='data'>
          <span className='dataKey'>API password:&nbsp;</span>
          <span className='dataValue'>{password}</span>
        </div>
        <div className='copyIcon'
            onClick={this.copyToClipboard}
        >
          {IconUtil.getIcon('ContentCopyIcon', undefined, 18)}
        </div>
      </div>
    )

    let contentToDisplay = reverseButtonElement;

    if (loadingSection) {
      contentToDisplay = (
        <>
          {reverseButtonElement}
          <UpdateSpinner className='spinner'
              classes='customerSpinner'
              size={80}
              thickness={5}
          />
        </>
      )
    } else if (shouldDisplayPassword) {
      contentToDisplay = credentialElement;
    }

    return (
      <div>
        <p className='apiPasswordText'>
          <span className='importantText'>{t('ApiCredential.Important')}</span>
          {t('ApiCredential.ApiPasswordContent')}
        </p>
        {contentToDisplay}
      </div>
    );
  }

  buildApiAccessPostBody({credentialType, applyAllMids ,enableWebHook = false, incrementalAuthToggle = false}) {
    const { jsSdkDomain, webhookDomain } = this.props;

    let body = {
      accessOurAPI: true
    };

    switch(credentialType) {
      case CredentialsType.iFrameAPI:
        body.accessJSSDK = true;
        body.jsSDKDomain = jsSdkDomain;
        body.applyAllMids = applyAllMids ? ['js_sdk'] : [];
        break;
      case CredentialsType.invoicingAPI:
        body.accessPayNow = true;
        body.applyAllMids = applyAllMids ? ['paynow'] : [];
        break;
      case CredentialsType.semiIntegratedAPI:
        body.semiIntegration = true;
        body.incrementalAuth = incrementalAuthToggle;
        body.applyAllMids = applyAllMids ? ['semi_integration'] : [];
        break;
      case CredentialsType.webhookDomain:
        body.accessWebhook = enableWebHook;
        body.webhookDomain = webhookDomain;
        body.applyAllMids = applyAllMids ? ['webhook'] : [];
        break;
      default:
        break;
    }
    return body;
  }

  buildApiAccessPutBody(data) {
    const { user, apiAccess } = this.props;
    const currentMid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(currentMid, apiAccess);

    return {
      ...currentMidSettings,
      ...this.buildApiAccessPostBody(data)
    };
  }

  async revokeAccessHelper(payload) {
    const { user, dispatch, t } = this.props;
    this.setState({ revokeAccessTriggered: null });
    try {
      await dispatch(putApiAccess(user, payload));
      await dispatch(getApiAccess(user));
      toastr.success(t('Success'), t('ApiAccess.MidRemoved'), {
        timeOut: 3000,
        icon: IconUtil.getIcon('CheckIcon', LabelUtil.getLabelColor()),
        progressBar: true,
        removeOnHover: false,
      });

    }
    catch(e) {
      toastr.error('Error', e.toString());
    }
  }
  async revokeAccess(payload) {
    const { verification } = this.props;
    if (!verification.twoFactorAuthenticationVerified[twoFactorTypes.isvApiAccess]) {
      this.setState({ revokeAccessTriggered: { data: payload } } );
      this.openTwoFactorDialog();
    } else {
      await this.revokeAccessHelper(payload);
    }
  }
  async requestCredentialsHelper(data) {
    const { verification } = this.props;

    this.setState({ requestCredentialsTriggered: { data } } );

    if (!verification.twoFactorAuthenticationVerified[twoFactorTypes.isvApiAccess]) {
      this.openTwoFactorDialog();
    } else if (data.credentialType === CredentialsType.iFrameAPI){
      this.openJsSdkForm();
    } else if (data.credentialType === CredentialsType.webhookDomain) {
      if (data.enableWebHook) {
        this.openWebHookForm();
      } else {
        return await this.requestCredentials(data, data.shouldToggle && this.toggleWebhook);
      }
    } else if (data.credentialType === CredentialsType.semiIntegratedAPI) {
      return await this.requestCredentials(data, data.shouldToggle && this.toggleIncrementalAuth);
    }
    else {
      return await this.requestCredentials(data);
    }
  }

  async on2faVerified(noEcp) {
    const { dispatch } = this.props;
    const { requestCredentialsTriggered, resetApiPasswordTriggered, revokeAccessTriggered } = this.state;
    const credentialData = requestCredentialsTriggered?.data || {};
    const revokeAccessData = revokeAccessTriggered?.data || {};

    if (!_.isEmpty(revokeAccessData)) {
      await this.revokeAccessHelper(revokeAccessData);
      return;
    }

    if (!_.isEmpty(credentialData)) {
      if (credentialData.credentialType === CredentialsType.iFrameAPI) {
        this.openJsSdkForm();
      } else if (credentialData.credentialType === CredentialsType.webhookDomain && requestCredentialsTriggered.data.enableWebHook) {
        this.openWebHookForm();
      } else if (credentialData.credentialType === CredentialsType.webhookDomain && !requestCredentialsTriggered.data.enableWebHook) {
        await this.requestCredentials(credentialData, credentialData.shouldToggle && this.toggleWebhook);
      } else if (credentialData.credentialType === CredentialsType.semiIntegratedAPI) {
        await this.requestCredentials(credentialData, credentialData.shouldToggle && this.toggleIncrementalAuth);
      } else {
        await this.requestCredentials(credentialData);
      }
    }

    if (noEcp) {
      resetApiPasswordTriggered && this.togglePasswordDialog(true);
    } else {
      resetApiPasswordTriggered && dispatch(setModalVisibility('apiPasswordResetConfirm'));
      !resetApiPasswordTriggered && !requestCredentialsTriggered && dispatch(setModalVisibility('hidden'));
    }
  }
  async requestCredentials(data, callback = () => null) {
    let { dispatch, user, apiAccess } = this.props;

    this.setState({ loadingSection: true });

    let currentMid = ApiAccessUtil.getCurrentMid(user);
    let currentMidSettings = ApiAccessUtil.getApiSettingsForMid(currentMid, apiAccess);

    let isPost =  _.isEmpty(currentMidSettings?.proxy);

    if (isPost) {
      const apiAccessPostBody = this.buildApiAccessPostBody(data);
      await dispatch(postApiAccess(user, apiAccessPostBody));
    } else {
      const apiAccessPutBody = this.buildApiAccessPutBody(data);
      await dispatch(putApiAccess(user, apiAccessPutBody));
    }

    const { apiAccess: upToDateApiAccess } = this.props;

    currentMid = ApiAccessUtil.getCurrentMid(user);
    currentMidSettings = ApiAccessUtil.getApiSettingsForMid(currentMid, upToDateApiAccess);

    let proxy = currentMidSettings.proxy?.find(proxyElement => proxyElement.sdk_primary_key);

    if (!proxy) {
      proxy = currentMidSettings.proxy?.find(proxyElement => !proxyElement.sdk_primary_key) || {};
    }

    if (Boolean(proxy?.password)) {
      this.setState({ password: proxy?.password }, () => {
        dispatch(setModalVisibility('apiPasswordDialog'));
      })
    }

    this.setState({ loadingSection: false, requestCredentialsTriggered: null });

    callback();
  };

  getCredentialsInfo() {
    const {
      user,
      apiAccess,
    } = this.props;

    const mid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(mid, apiAccess);
    const {
      hasWebhookPendingConfig,
      hasPaynowPendingConfig,
      hasJSSDKPendingConfig,
      hasSemiIntegrationPendingConfig
    } = currentMidSettings;

    let proxy = currentMidSettings.proxy?.find(proxyElement => proxyElement.sdk_primary_key);

    if (!proxy) {
      proxy = currentMidSettings.proxy?.find(proxyElement => !proxyElement.sdk_primary_key) || {}
    }

    const payNowToken = proxy?.sdk_key;
    const sdkAppSourceHeader = proxy?.sdk_app_source_header;

    const pendingConfigurations = {
      [CredentialsType.webhookDomain]: hasWebhookPendingConfig,
      [CredentialsType.invoicingAPI]: hasPaynowPendingConfig,
      [CredentialsType.iFrameAPI]: hasJSSDKPendingConfig,
      [CredentialsType.semiIntegratedAPI]: hasSemiIntegrationPendingConfig,
    }

    const iFrameAPI = currentMidSettings.accessJSSDK ? {
      gateway_public_key: currentMidSettings.jsSDKToken,
      ['x-nabwss-appsource']: sdkAppSourceHeader,
      jsSDKDomain: currentMidSettings.jsSDKDomain,
      developerKey: payNowToken,
    } : null;

    const invoicingAPI = currentMidSettings.accessPayNow ? {
      ['x-nabwss-appsource']: sdkAppSourceHeader,
      developerKey:  payNowToken,
    } : null;

    const semiIntegratedAPI = currentMidSettings.semiIntegration ? {
      gateway_public_key: currentMidSettings.semiIntegrationGatewayPublicKey,
      ['x-nabwss-appsource']: sdkAppSourceHeader,
      developerKey:  payNowToken,
    } : null;

    return {
      mid,
      iFrameAPI,
      invoicingAPI,
      semiIntegratedAPI,
      webhookDomain: currentMidSettings.webhookDomain,
      pendingConfigurations,
    }
  }

  closeTwoFactorDialog() {
    const { dispatch } = this.props;
    this.setState({
      resetApiPasswordTriggered: false,
      requestCredentialsTriggered: false,
    }, () => {
      dispatch(setModalVisibility('hidden'));
    });
  }

  openTwoFactorDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('twoFactorDialog'));
  }

  showToasterSuccess() {
    toastr.success('Success', 'Update successful', {
      timeOut: 3000,
      icon: IconUtil.getIcon('CheckIcon', LabelUtil.getLabelColor()),
      progressBar: true,
      removeOnHover: false,
    });
  }

  on2faProcessing(state) {
    this.setState({ loadingSection: state });
  }

  on2faClose() {
    this.setState({
      resetApiPasswordTriggered: false,
      requestCredentialsTriggered: false
    });
  }

  renderCredentialSections(creds = []) {
    const { t, dispatch, user, auth, apiAccess } = this.props;
    const { incrementalAuthToggle } = this.state;

    const credentials = this.getCredentialsInfo();

    const credentialSections = creds.length > 0 ? creds : [
      {
        key: CredentialsType.iFrameAPI,
        type: CredentialsType.iFrameAPI,
        hasAccess: credentials.iFrameAPI,
        title: 'iFrame JavaScript SDK',
        error:  credentials.pendingConfigurations[CredentialsType.iFrameAPI] ? ` ${t('ApiAccess.NotActive')} ` : null,
        info: t('ApiAccess.JSKInfo'),
        link:'https://developer.north.com/products/online/iframe-javascript-sdk',
        data: credentials.iFrameAPI && [
          {
            key: 'mid',
            value: credentials.mid
          },
          {
            key: 'developerKey',
            value: credentials.iFrameAPI.developerKey
          },
          {
            key: 'API password',
            value: 'Already provided',
            info: t('ApiCredential.APIInfo'),
            action: {
              text: t('ApiCredential.ResetAPIPassword'),
              fn: this.resetPassword
            }
          },
          {
            key: 'x-nabwss-appsource header',
            value: credentials.iFrameAPI['x-nabwss-appsource']
          },
          {
            key: 'gateway_public_key',
            value: credentials.iFrameAPI.gateway_public_key
          },
        ],
        formSection: credentials.iFrameAPI && {
          title: t('ApiAccess.JSKDomain'),
          info: t('ApiAccess.JSKDomainInfo'),
          value: credentials.iFrameAPI.jsSDKDomain,
          position: '2',
          action: {
            fn: this.openJsSdkFormHelper,
            text: t('Edit')
          }
        }
      },
      {
        key: CredentialsType.invoicingAPI,
        type: CredentialsType.invoicingAPI,
        hasAccess: credentials.invoicingAPI,
        title: 'Invoicing API',
        error:  credentials.pendingConfigurations[CredentialsType.invoicingAPI] ? ` ${t('ApiAccess.NotActive')} ` : null,
        info: t('ApiAccess.InvoicingAPIInfo'),
        link: 'https://developer.north.com/products/online/invoicing-api',
        data: credentials.invoicingAPI && [
          {
            key: 'mid',
            value: credentials.mid
          },
          {
            key: 'developerKey',
            value: credentials.invoicingAPI.developerKey
          },
          {
            key: 'API password',
            value: 'Already provided',
            info: t('ApiCredential.APIInfo'),
            action: {
              text: t('ApiCredential.ResetAPIPassword'),
              fn: this.resetPassword,
            }
          },
          {
            key: 'x-nabwss-appsource header',
            value: credentials.invoicingAPI['x-nabwss-appsource']
          },
        ],
      },
      {
        key:  CredentialsType.semiIntegratedAPI,
        type:  CredentialsType.semiIntegratedAPI,
        hasAccess: credentials.semiIntegratedAPI,
        title: t('ApiAccess.SemiIntegratedAPI'),
        error:  credentials.pendingConfigurations[CredentialsType.semiIntegratedAPI] ? ` ${t('ApiAccess.NotActive')} ` : null,
        formSection: {
          title: t('ApiAccess.AllowIncrementalAuthorization'),
          info: t('ApiAccess.AuthoInfo'),
          toggle: {
            action: this.toggleAllowIncrementalAuthHelper,
            value: incrementalAuthToggle
          },
          position: '1'
        },
        info: t('ApiAccess.SemiIntegratedAPIInfo'),
        link: 'https://developer.north.com/products/in-person/semi-integrated/north-si-cloud-api',
        data: credentials.semiIntegratedAPI && [
          {
            key: 'mid',
            value: credentials.mid
          },
          {
            key: 'developerKey',
            value: credentials.semiIntegratedAPI.developerKey
          },
          {
            key: 'API password',
            value: 'Already provided',
            info: t('ApiCredential.APIInfo'),
            action: {
              text: t('ApiCredential.ResetAPIPassword'),
              fn: this.resetPassword
            }
          },
          {
            key: 'x-nabwss-appsource header',
            value: credentials.semiIntegratedAPI['x-nabwss-appsource']
          },
          {
            key: 'gateway_public_key',
            value: credentials.semiIntegratedAPI.gateway_public_key
          },
        ]
      }
    ];

    return credentialSections
      .map((credential) => (
        <ApiCredential
            apiAccess={apiAccess}
            auth={auth}
            copyToClipboard={this.copyToClipboard}
            credentialInfo={credential}
            dispatch={dispatch}
            hasAccess={credential.hasAccess}
            key={credential.key}
            requestCredentials={this.requestCredentialsHelper}
            revokeAccess={this.revokeAccess}
            t={t}
            user={user}
        />));
  }

  async confirmJsSdkForm () {
    const { dispatch } = this.props;
    await dispatch(submit('JsSdkForm'));
    this.closeDialog();
  }

  async confirmWebHookForm () {
    const { dispatch } = this.props;
    await dispatch(submit('WebHookForm'));
    this.closeDialog();
  }

  closeDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }
  openJsSdkForm() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('jsSdkFormDialog'));
  }

  openWebHookForm() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('webHookFormDialog'));
  }

  verificationNotice(number) {
    const { t } = this.props;
    return t('ApiAccess.VerificationNotice', { number });
  }

  submitJsSdkForm(data) {
    const { user, apiAccess } = this.props;
    const { requestCredentialsTriggered } = this.state;
    const currentMid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(currentMid, apiAccess);
    const { jsSDKDomain } = currentMidSettings;

    let callback = undefined;
    if (jsSDKDomain) {
      callback = this.showToasterSuccess;
    }

    return this.requestCredentials({
      credentialType: CredentialsType.iFrameAPI,
      applyAllMids: requestCredentialsTriggered?.data?.applyAllMids,
      ...data
    }, callback)
  }

  render() {
    const { settingsMenuItems, handleMenuSelection, t, merchantSettings, apiAccess , user } = this.props;
    const currentMid = ApiAccessUtil.getCurrentMid(user);
    const currentMidSettings = ApiAccessUtil.getApiSettingsForMid(currentMid, apiAccess);

    const {
      requestCredentialsTriggered,
      revokeAccessTriggered,
      shouldDisplayPassword,
      loadingSection,
      webhookToggle,
      loading
    } = this.state;

    const {
      userExperience: {
        modalVisibility: {
          jsSdkFormDialog,
          webHookFormDialog,
          apiPasswordDialog,
          apiPasswordResetConfirm
        }
      },
      formReducer
    } = this.props;

    const {  WebHookForm: webHookForm, JsSdkForm: jsSdkForm } = formReducer || {};

    const filterPanel = (
      <FilterPanel
          {...this.props}
          filterData={settingsMenuItems}
          selectFilterCallback={handleMenuSelection}
      />
    );

    const pageLoading = apiAccess.isFetching || !merchantSettings?.merchantSettings || merchantSettings.isProcessing || loading;

    if (pageLoading) {
      return (
        <Page
            loading
            title={t('Business Settings')}
        />
      )
    }


    const customModal = (requestCredentialsTriggered || revokeAccessTriggered) ? {} : {
      title: t('ApiCredential.ResetAPIPassword'),
      centerTitle: true,
      resendMessage: t('ResendCode'),
      enterCodeMessage: t('EnterVerificationCode'),
      verificationNotice: this.verificationNotice,
      paperProps: resetPasswordStyles()
    }

    const enableWebHook = {
      credentialType: CredentialsType.webhookDomain,
      applyAllMids: requestCredentialsTriggered?.data?.applyAllMids,
      enableWebHook: true
    };

    const credentials = this.getCredentialsInfo();
    const webhookSection = {
      key: CredentialsType.webhookDomain,
      type: CredentialsType.webhookDomain,
      hasAccess: currentMidSettings.accessWebhook,
      data: [],
      error:  credentials.pendingConfigurations[CredentialsType.webhookDomain] ? ` ${t('ApiAccess.NotActiveWebhook')} ` : null,
      noButton: true,
      formSection: {
        title: t('ApiAccess.WebHook'),
        toggle: {
          action: this.toggleWebhookHelper,
          value: webhookToggle
        },
        info: t('ApiAccess.WebHookInfo'),
        value: credentials.webhookDomain,
        action: {
          fn: this.openWebHookFormHelper,
          text: t('Edit')
        },
        position: '2',
      }
    };

    return (
      <>
        <div className='businessProfile pageWrap'>
          <Page
              initialSubpage={t('API Access')}
              loading={pageLoading}
              title={t('Business Settings')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
              {filterPanel}
              <div
                  className='apiAccessHolder'
              >
                <div className='headerTitleContainer'>
                  <div className='titleText'>
                    {t('ApiAccess.Title')}
                  </div>
                  <div className='subText'>
                    {t('ApiAccess.ApiAccessInfo')}
                  </div>
                </div>
                <div>
                  <p className='subTitle'>
                    {t('ApiAccess.Credentials')}
                  </p>
                  {this.renderCredentialSections()}
                  <p className='subTitle'>
                    {t('ApiAccess.WebhookTitle')}
                    {webhookSection.error ? <span
                        className='error'
                                            >{webhookSection.error}</span> : ''}
                  </p>
                  {this.renderCredentialSections([webhookSection])}
                </div>
              </div>
            </div>
          </Page>
        </div>
        <>
          <TwoFactorTools
              custom={customModal}
              onClose={this.on2faClose}
              onProcessing={this.on2faProcessing}
              onVerified={this.on2faVerified}
              type={twoFactorTypes.isvApiAccess}
              {...this.props}
          />

          <MessageDialog
              bodyText={this.getResetPasswordDialogContent()}
              confirmText='Done'
              dialogBodyClassName='apiPasswordContent'
              disabled={!shouldDisplayPassword}
              externalClassName='apiCredentialDialog'
              hideCloseIcon
              isChoiceRequired
              onConfirm={this.togglePasswordDialog.bind(this, false)}
              onRequestClose={this.togglePasswordDialog.bind(this, false)}
              open={apiPasswordDialog}
              titleText='API Password'
          />

          <Modal
              cancelText={t('Cancel')}
              confirmText={t('Save')}
              externalClassName='jsSdkFormDialog'
              isConfirmDisabled={jsSdkForm?.syncErrors?.jsSdkDomain}
              maxWidth='sm'
              onClose={this.closeDialog}
              onConfirm={this.confirmJsSdkForm}
              open={jsSdkFormDialog}
              subtitle={
              currentMidSettings.jsSDKDomain !== '' ?
                <>
                  <span className='importantText'>{t('JsSdkForm.Important')}</span>
                  {t('JsSdkForm.Message')}
                </>
                :
                <>
                  {t('JsSdkForm.EnterJsSdk')}
                </>
            }
              title={t('JsSdkForm.Title')}
          >
            <JsSdkForm
                onSubmit={this.submitJsSdkForm}
                t={t}
                value={currentMidSettings.jsSDKDomain}
            />
          </Modal>

          <Modal
              cancelText={t('Cancel')}
              confirmText={t('Save')}
              externalClassName='webHookFormDialog'
              isConfirmDisabled={webHookForm?.syncErrors?.webhookDomain}
              maxWidth='sm'
              onClose={this.closeDialog}
              onConfirm={this.confirmWebHookForm}
              open={webHookFormDialog}
              subtitle={
              currentMidSettings.webhookDomain !== '' ?
                <>
                  <span className='importantText'>{t('WebHookForm.Important')}</span>
                  {t('WebHookForm.Message')}
                </>
                :
                <>
                  {t('WebHookForm.EnterDomain')}
                </>
            }
              title={t('WebHookForm.Title')}
          >
            <WebHookForm
                onSubmit={
                this.requestCredentials.bind(this, enableWebHook, !webhookToggle ? this.toggleWebhook : this.showToasterSuccess)}
                t={t}
                value={currentMidSettings.webhookDomain}
            />
          </Modal>

          <MessageDialog
              bodyText={
              <div className='apiPasswordText'>
                <p><span className='importantText'>{t('ApiAccess.Important')} </span>{t('ApiAccess.AreYouSure')}</p>
                <p>{t('ApiAccess.PasswordResetContent')}</p>
              </div>
            }
              cancelText={t('Cancel')}
              confirmText={t('Ok')}
              dialogBodyClassName='apiResetPasswordContent'
              externalClassName='apiResetCredentialDialog'
              isChoiceRequired={false}
              onConfirm={() => this.togglePasswordDialog(true)}
              onRequestClose={this.closeDialog}
              open={apiPasswordResetConfirm}
              titleText={t('ApiAccess.PasswordReset')}
          />

          {loadingSection && <UpdateSpinner className='spinner'
              classes='customerSpinner'
              size={80}
              thickness={5}
                             />}
        </>
      </>
    )
  }
}

export function mapStateToProps(state) {
  const jsSdkFormValues = getFormValues('JsSdkForm')(state) || {};
  const webHookFormValues = getFormValues('WebHookForm')(state) || {};
  return {
    jsSdkDomain: jsSdkFormValues.jsSdkDomain,
    webhookDomain: webHookFormValues.webhookDomain,
  };
}

export default connect(mapStateToProps)(ApiAccess);
