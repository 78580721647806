/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {Box} from '@mui/material';

import _ from 'lodash';

import TodayDataUtil from '../util/TodayDataUtil';
import IconUtil from '../util/IconUtil';
import DateUtil from '../util/DateUtil';
import UserUtil from '../util/UserUtil';
import Image from '../shared/Image';
import { buttonText } from '../../constants/planInformation';

import NorthDashboardBanner from './NorthDashboardBanner';
import HistoricHardwareModeBanner from './HistoricHardwareModeBanner';
import North from '../whitelabel/North';
import { isNorth as isNorthLabel } from '../util/WhiteLabelUtil';
import Feature from './cards/Feature';
import TopReports from './cards/TopReports';
import Activity from './cards/Activity';
import LoadingCard from './cards/LoadingCard';
import QuickActions from './cards/QuickActions';
import IntegrationsCarousel from './cards/IntegrationsCarousel';
import routes from './../../constants/routes';
import {
  dashboardFooterStyle,
  dashboardHeaderStyle,
  dashboardStyles
} from '../../jss/dashboardStyles';
import Page from '../shared/Page';
import classNames from 'classnames';
import {getDavoStatus} from '../../actions/accountProfileActions';
import { getActivitiesDashboard } from '../../actions/activitiesActions';
import DoughnutChart from '../../components/shared/charts/DoughnutChart';
import BarChart from '../../components/shared/charts/BarChart';
import moment from 'moment';
import { loadingStyle } from '../../jss/inlineStyles';
import ActivationUtil from '../util/ActivationUtil';

export default class Dashboard extends Component {

  componentDidMount() {
    const {user, dispatch} = this.props;
    dispatch(getActivitiesDashboard(user));
  }

  componentDidUpdate(prevProps) {
    const {merchantSettings, user, dispatch} = this.props;

    const merchantSettingsFetching = merchantSettings.isProcessing !== prevProps.merchantSettings.isProcessing;
    const role = TodayDataUtil.getRole(user?.data?.merchantAccounts, user?.selectedMerchantAccount);
    const isOwnerAdmin = role === 'owner' || role === 'admin';
    if (isOwnerAdmin && !merchantSettings.isProcessing && merchantSettingsFetching && !_.isNull(merchantSettings.merchantSettings)) {
      merchantSettings.merchantSettings.davo_enabled && dispatch(getDavoStatus(user));
    }
  }

  render() {

    const {
      accountProfile,
      dispatch,
      employees,
      history,
      merchantSettings,
      t,
      user,
      activation,
      activities: { dashboard, isFetching },
      userExperience
    } = this.props;

    const { historicHardwareMode } = userExperience;

    const role = TodayDataUtil.getRole(user?.data?.merchantAccounts, user?.selectedMerchantAccount);
    const currentUserRole = t(`EmployeeDetailForm.Roles.${_.capitalize(role)}`);

    const selectedMerchantAccount = _.find(user?.data?.merchantAccounts, { mea_id: Number(user?.selectedMerchantAccount) });
    const employeeData = employees ? _.find(employees.data, { id: Number(selectedMerchantAccount?.mid_user_id) }) : null;
    const employeeName = employeeData?.first_name;

    const currentPlanAccount = UserUtil.accountCurrentPlan(user);

    const todayClass = classNames('pageScrollableArea', 'today');

    const isNorth = isNorthLabel();

    const tapToPayIcon = IconUtil.getIcon('TapToPay');
    const loyaltyIcon = IconUtil.getIcon('Loyalty');
    const reputationManagementIcon = IconUtil.getIcon('ReputationManagement');

    const premiumPlusIcon = IconUtil.getIcon('PremiumPlus');
    const integrationsIcon = <img src={'/images/IntegrationsDashboard.png'}/>;
    const yelpIntegrationImage = (<Image alt={t('YelpIntegrationDescription')}
        src={'/images/yelp/yelpTile.svg'}
                                  />);
    const yelpIntegrationIcon = (<Image
        alt={'Yelp'}
        src={'/images/yelp/yelpLogo.png'}
        sx={{ width: '168px' }}
                                 />);

    const navIntegrationImage = (
      <Image
          alt={t('IntegrationsPage.Nav.IntegrationTile.Text')}
          src={'/images/nav/navTile.svg'}
      />
    );

    const navIntegrationIcon = (
      <Image
          alt={'Nav'}
          src={'/images/nav/navSquareLogo.svg'}
          sx={{ width: '168px' }}
      />
    );

    const davoIntegrationImage = (
      <Image
        alt={t('DAVOIntegration.Title')}
        src={'/images/davo/davoTile.svg'}
      />
    );

    const davoIntegrationIcon = (
      <Image
        alt={'Nav'}
        src={'/images/davo/davoSquareLogo.svg'}
        sx={{ width: '168px' }}
      />
    );

    const nextIntegrationImage = (
      <Image
          alt={t('IntegrationsPage.Next.IntegrationTile.Text')}
          src={'/images/next/nextTile.png'}
      />
    );

    const nextIntegrationIcon = (
      <Image
          alt={'Next'}
          src={'/images/next/nextSquareLogo.png'}
          sx={{ width: '168px' }}
      />
    );

    const isOwnerAdmin = role === 'owner' || role === 'admin';

    const shouldShowACH = merchantSettings?.merchantSettings?.ach_enabled;
    const shouldShowCash = merchantSettings?.merchantSettings?.accept_cash_payments;
    const shouldShowGiftCard = merchantSettings?.merchantSettings?.gc_type;

    const shouldShowYelpTile = !merchantSettings?.merchantSettings?.yelp_clicked;
    const shouldShowNavTile = !merchantSettings?.merchantSettings?.nav_clicked;
    const shouldShowNextTile = !merchantSettings?.merchantSettings?.next_clicked;
    const shouldShowDavoTile = !accountProfile.davo?.is_integrated;

    const exploreIntegrationsTile = (
      <Feature
          customClass='integrationsFeature'
          icon={integrationsIcon}
          isNorth={isNorth}
          t={t}
          text='IntegrationsDescription'
          title='ExploreIntegrations'
          titlePos='up'
      />
    );

    const yelpTile = (
      <Feature
          customClass='integrationsFeature'
          icon={yelpIntegrationImage}
          integrationIcon={yelpIntegrationIcon}
          linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
          linkText='IntegrationsPage.Yelp.TileText'
          t={t}
          text='YelpIntegrationDescription'
          title='IntegrationsPage.FeaturedIntegration'
          titlePos='up'
      />
    );

    const navTile = (
      <Feature
          customClass='integrationsFeature'
          icon={navIntegrationImage}
          integrationIcon={navIntegrationIcon}
          linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
          linkText='IntegrationsPage.Nav.IntegrationTile.Action'
          t={t}
          text='IntegrationsPage.Nav.IntegrationTile.Text'
          title='IntegrationsPage.FeaturedIntegration'
          titlePos='up'
      />
    );

    const davoTile = (
      <Feature
          customClass='integrationsFeature'
          disclaimer='DAVOIntegration.Disclaimer'
          icon={davoIntegrationImage}
          integrationIcon={davoIntegrationIcon}
          linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
          linkText='DAVOIntegration.LinkText'
          t={t}
          text='DAVOIntegration.Title'
          title='IntegrationsPage.FeaturedIntegration'
          titlePos='up'
      />
    );

    const nextTile = (
      <Feature
          customClass='integrationsFeature'
          icon={nextIntegrationImage}
          integrationIcon={nextIntegrationIcon}
          linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
          linkText='IntegrationsPage.Next.IntegrationTile.Action'
          t={t}
          text='IntegrationsPage.Next.IntegrationTile.Text'
          title='IntegrationsPage.FeaturedIntegration'
          titlePos='up'
      />
    );

    const shouldShowCarousel = isOwnerAdmin && (shouldShowYelpTile || shouldShowNavTile || shouldShowNextTile || shouldShowDavoTile);
    const carouselTiles = shouldShowCarousel ? [] : null;
    if (shouldShowCarousel) {
      carouselTiles.push(exploreIntegrationsTile);
      if (shouldShowYelpTile) carouselTiles.push(yelpTile);
      if (shouldShowNextTile) carouselTiles.push(nextTile);
      if (shouldShowNavTile) carouselTiles.push(navTile);
      if (shouldShowDavoTile) carouselTiles.push(davoTile);
    }

    const logo = isNorth ? IconUtil.getIcon('NorthLogo') : IconUtil.getIcon('PaymentsHubLogo');
    const powerUserIcon = IconUtil.getIcon('PowerUser', isNorth ? '#1857F7' : '#00C9BA', '215px');

    const isManager = role === 'manager';
    const quickReportsVisible = isOwnerAdmin || (isManager && selectedMerchantAccount.manager_activity_access_enabled);
    const displayBanner = ActivationUtil.getCurrentApplicationStatus(activation, user)?.shouldDisplayBanner;
    const isMBTorHBT = UserUtil.isMybizperks(user) || UserUtil.isHbt(user)

    const label = globalApplicationLabel.path.slice(0, -1);
    const noData = <span>{IconUtil.getIcon('NoData')}<p>{t('NoDataMessage')}</p></span>;
    const noDataIndicator = !dashboard?.paymentMethodsVolume && noData;
    const loadingIndicator = noDataIndicator && isFetching && <LoadingCard style={loadingStyle} />;
    const cardPlaceholder = loadingIndicator || noDataIndicator;
    const chartPlaceholder = dashboard?.activity?.transactionsCount === 0 ? noData : cardPlaceholder;
    const formattedYesterdayDate = moment().subtract(1, 'day').startOf('day').format('MMMM D, YYYY');
    const cardsLabels = ['Visa', 'MC', 'Amex', 'Disc'];
    const chartData = [
      dashboard?.paymentMethodsVolume?.visa.percentage,
      dashboard?.paymentMethodsVolume?.masterCard.percentage,
      dashboard?.paymentMethodsVolume?.americanExpress.percentage,
      dashboard?.paymentMethodsVolume?.discover.percentage
    ];
    const currentData = [
      dashboard?.paymentMethodsVolume?.visa.total,
      dashboard?.paymentMethodsVolume?.masterCard.total,
      dashboard?.paymentMethodsVolume?.americanExpress.total,
      dashboard?.paymentMethodsVolume?.discover.total
    ];

    if (!chartPlaceholder && shouldShowCash) {
      cardsLabels.push(t('Cash'));
      chartData.push(dashboard.paymentMethodsVolume.cash.percentage);
      currentData.push(dashboard.paymentMethodsVolume.cash.total);
    }

    if (!chartPlaceholder && shouldShowACH) {
      cardsLabels.push(t('Savings'));
      chartData.push(dashboard.paymentMethodsVolume.savings.percentage);
      currentData.push(dashboard.paymentMethodsVolume.savings.total);
      cardsLabels.push(t('Checking'));
      chartData.push(dashboard.paymentMethodsVolume.checking.percentage);
      currentData.push(dashboard.paymentMethodsVolume.checking.total);
    }

    if (!chartPlaceholder && shouldShowGiftCard) {
      cardsLabels.push(t('IntegrationsPage.Tags.GiftCard'));
      chartData.push(dashboard.paymentMethodsVolume.giftCard.percentage);
      currentData.push(dashboard.paymentMethodsVolume.giftCard.total);
    }

    let integrationSection = null;
    if (shouldShowCarousel) {
      integrationSection = (
        <IntegrationsCarousel>
          {carouselTiles.map((tile) => (<Box key={tile.props.text}>{tile}</Box>))}
        </IntegrationsCarousel>
      );
    } else if (isOwnerAdmin) {
      integrationSection = (<Box>{exploreIntegrationsTile}</Box>);
    }

    return (
      <>
        <Page
            hideBreadcrumbs
            title={t('Dashboard')}
        >
          <section className={todayClass}>

          <Box className='dashboardHeaderBox'
              sx={dashboardHeaderStyle}
          >
            <p>{t('welcome')}{employeeName ? `, ${employeeName}` : null}</p>
            <p>{currentUserRole} | {buttonText[currentPlanAccount]} | {DateUtil.getTodayWithoutTime().format('MMMM D, YYYY')}</p>
          </Box>

          <Box sx={dashboardStyles(quickReportsVisible)}>

            { historicHardwareMode ?

              <Box className='Status historicHardwareModeBanner'>
                <HistoricHardwareModeBanner dispatch={dispatch}
                    t={t}
                />
              </Box>

              : <>
                <Box>
                    <div className='snapshotTitle'>{t('Snapshot')} {formattedYesterdayDate}</div>
                    <Box className='Snapshot'>
                      <h2>{t('Activity')}</h2>
                      {cardPlaceholder || <Activity activity={dashboard.activity} {...this.props} />}
                    </Box>
                </Box>
                <Box className='Snapshot chart'>
                  <h2>{t('TransactionVolume')}</h2>
                  {chartPlaceholder || <div className='barChart'>
                    <BarChart
                      chartLabels={cardsLabels}
                      currentData={currentData}
                      yAxesType='$'
                      height={215}
                      simple
                      t={t}
                    />
                </div>}
                </Box>
                <Box className='Snapshot chart'>
                  <h2>{t('PaymentMethodBreakdown')}</h2>
                  {chartPlaceholder || <div className='donutChart'>
                    <DoughnutChart
                      chartLabels={cardsLabels}
                      chartData={chartData}
                      simple
                      t={t}
                    />
                  </div>}
                </Box>
                <Box className='Status'>
                  {displayBanner && (
                    <North>
                      <NorthDashboardBanner
                        activation={activation}
                        t={t}
                        dispatch={dispatch}
                        user={user}
                        history={history}
                        userExperience={userExperience}
                      />
                    </North>
                  )}
                </Box>

                <Box className='Links'>
                  <QuickActions history={history}
                      isMBTorHBT={isMBTorHBT}
                      merchantSettings={merchantSettings}
                      role={role}
                      selectedMerchantAccount={selectedMerchantAccount}
                      t={t}
                      user={user}
                  />
                </Box>

                {quickReportsVisible && (
                  <Box className='Reports'>
                    <TopReports {...this.props}
                        isMBTorHBT={isMBTorHBT}
                        isNorth={isNorth}
                        role={role}
                        selectedMerchantAccount={selectedMerchantAccount}
                        t={t}
                    />
                  </Box>
                )}

                { !isMBTorHBT &&
                  <>
                    <Feature
                        heading='MoreFeatures'
                        customClass={'initialRow'}
                        icon={tapToPayIcon}
                        link='https://www.payanywhere.com/why-payanywhere/tap-to-pay-on-iphone'
                        t={t}
                        text='TapToPay.Text'
                        title='TapToPayLabel'
                    />
                    <Feature
                        icon={loyaltyIcon}
                        internalLink
                        link={`${label}${routes.account.business}${routes.business.customers}`}
                        t={t}
                        text='LoyaltyDescription'
                        title={isNorth ? 'LoyaltyNorth' : 'LoyaltyPayAnywhere'}
                    />
                    <Feature
                        icon={reputationManagementIcon}
                        internalLink
                        link={`${label}${routes.account.business}${routes.business.reputation}`}
                        t={t}
                        text='ReputationManagementDescription'
                        title='ReputationScreen.ReputationManagement'
                    />
                    <Feature
                        data-test-id='NorthDevelopersTile'
                        background='gradient'
                        customClass={'initialRow'}
                        heading={isNorth ? 'NorthDevelopers.HeaderNH' : 'NorthDevelopers.HeaderPH'}
                        icon={premiumPlusIcon}
                        link={'https://developer.north.com/'}
                        t={t}
                        text='NorthDevelopers.Description'
                        title={'NorthDevelopers.Title'}
                        titlePos='up'
                    />
                    {integrationSection}
                    <Feature
                        customClass='powerUserFeature'
                        icon={powerUserIcon}
                        isNorth={isNorth}
                        link='https://vimeo.com/showcase/5592900'
                        t={t}
                        text='PowerUserText'
                        title='PowerUser'
                        titlePos='up'
                    />
                  </>
                }
              </>
            }

          </Box>

          <Box className='dashboardFooterBox'
              sx={dashboardFooterStyle}
          >
            {logo}
            <p>{t(isNorth ? 'DashboardNorthDisclaimer' : 'DashboardDisclaimer')}</p>
          </Box>

        </section>
        </Page>
      </>
    );
  }
}

Dashboard.defaultProps = {
  isFetching: true,
};
